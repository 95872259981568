import i18n from '@shared/i18n/vue-i18n'
import * as api from '@shared/http/api'
import { fromJS } from 'immutable'
import { DateTime } from 'luxon'
import { getAttributeTranslation } from '@shared/helpers/attributes'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
    sessionExpiredAt: null,
    reauthenticationNeeded: false,
    sessionExpirationTimer: 0,
  },

  getters: {
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },

    SET_USER(state, value) {
      state.user = value
    },

    ADAPT_UNREAD_NOTIFICATIONS_COUNTER(state, value) {
      const updatedUser = fromJS(state.user).toJS()
      updatedUser.attributes.unread_notifications_count += value
      state.user = updatedUser
    },

    REFRESH_SESSION_EXPIRATION(state) {
      const sessionLifeTimeInMinutes = import.meta.env.VITE_SESSION_LIFETIME
      const expiredAt = DateTime.now().plus({ minutes: sessionLifeTimeInMinutes })
      state.sessionExpiredAt = expiredAt
    },

    SET_REAUTHENTICATION_NEEDED(state, value) {
      if (value) {
        // stop listening to incoming notifications
        window.Echo.leave(`App.Models.User.${state.user?.id}`)
      }

      state.reauthenticationNeeded = value
    },

    RESET_SESSION_EXPIRATION(state) {
      state.sessionExpiredAt = null
      state.reauthenticationNeeded = false
      if (state.sessionExpirationTimer) {
        clearTimeout(state.sessionExpirationTimer)
      }
    },

    CLEAR_SESSION_EXPIRATION_TIMER(state) {
      if (state.sessionExpirationTimer) {
        clearTimeout(state.sessionExpirationTimer)
      }
    },
    SET_SESSION_EXPIRATION_TIMER(state, value) {
      state.sessionExpirationTimer = value
    },
  },

  actions: {
    async getAuthenticatedUser({
      commit,
      state,
      dispatch,
    }) {
      let user = null

      // Reset authentication needed to false before api calls
      commit('SET_REAUTHENTICATION_NEEDED', false)

      // Retrieve authenticated user
      await api.fetchCurrentUser().then(({ data }) => {
        if (data?.data?.id) user = data.data
      })

      setLocale(user)

      commit('SET_AUTHENTICATED', user !== null)
      commit('SET_USER', user)
      dispatch('refreshGeoloc')

      setDefaultGeolocFilter(user, dispatch, state)
    },

    // Try to sign out from API, then from SPA
    async signOutFromApi({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        // Ignore if already logged out
        if (!state.authenticated) {
          reject()
        }

        api.logout()
          .then(() => {
            dispatch('signOut')
            resolve()
          })
          .catch(() => {
            reject()
          })
      })
    },

    // Sign out from SPA
    async signOut({ commit, state }) {
      // Stop listening to incoming notifications
      window.Echo.leave(`App.Models.User.${state.user?.id}`)

      // Set authenticated to false, so all authenticated layout disappear
      commit('SET_AUTHENTICATED', false)
      // Remove authenticated user from store
      commit('SET_USER', null)
      // Reset expiration after unsetting the authenticated user
      commit('RESET_SESSION_EXPIRATION')
    },

    async redirectAuthenticatedUser({ state, rootState }) {
      if (
        rootState.appName === 'extranet'
        && (
          state.user.attributes.should_choose_plan
          || state.user.attributes.should_pay_subscription
        )
      ) {
        // Redirect user to subscriptions page if needed
        await rootState.router.router.push({ name: 'subscriptions' })
      } else if (rootState.router.afterLoginRedirectRoute) {
        // Redirect user to the specified path after login
        await rootState.router.router.push(rootState.router.afterLoginRedirectRoute)
      } else {
        await rootState.router.router.push('/')
      }
    },

    async refreshGeoloc(
      {
        commit,
        state,
        rootState,
        dispatch,
      },
      retryCallback,
    ) {
      return new Promise((resolve, reject) => {
        // Only in traveller app and if user has "around_me" geoloc setting's kind
        if (
          rootState.appName === 'app'
          && state.user?.relationships?.geoloc_setting?.attributes?.kind === 'around_me'
        ) {
          navigator.geolocation.getCurrentPosition(
            // Geolocation success callback
            (position) => {
              const newLatitude = position.coords.latitude
              const newLongitude = position.coords.longitude

              // Update user's coords
              api.updateGeolocSetting({
                data: {
                  type: 'usergeolocsettings',
                  attributes: {
                    latitude: newLatitude,
                    longitude: newLongitude,
                  },
                },
              }).then((response) => {
                // Save user geoloc setting in store
                const updatedUser = state.user
                updatedUser.relationships.geoloc_setting = response.data.data
                commit('SET_USER', updatedUser)

                resolve()
              }).catch((error) => {
                reject(error)
              })
            },
            (error) => {
              // Show "geoloc failed" modal
              commit('modals/SHOW', 'geoloc_failed', { root: true })

              // Set "geoloc failed" retry callback
              commit(
                'modals/SET_PROPERTIES',
                {
                  name: 'geoloc_failed',
                  retry_callback: retryCallback ?? (() => dispatch('refreshGeoloc')), // Retry provided callback, or retry self
                  error_code: error.code,
                },
                {
                  root: true,
                },
              )

              reject(error)
            },
          )
        } else {
          resolve()
        }
      })
    },
  },
}

// Apply user locale
function setLocale(user) {
  if (user?.attributes?.locale) {
    i18n.global.locale.value = user?.attributes?.locale
  }
}

// Set user's POI's address's coords as default filters
function setDefaultGeolocFilter(user, dispatch, state) {
  const pointOfInterest = user
    ?.relationships
    ?.point_of_interest
  const addressAttributes = pointOfInterest
    ?.relationships
    ?.address
    ?.attributes

  if (
    addressAttributes?.latitude
    && addressAttributes?.longitude
    && getAttributeTranslation(pointOfInterest?.attributes?.title)
  ) {
    dispatch(
      'filters/updateDefaultFilters',
      {
        type: 'pointsOfInterest',
        values: {
          latitude: addressAttributes.latitude,
          longitude: addressAttributes.longitude,
          geoloc: getAttributeTranslation(pointOfInterest.attributes.title),
          limit_to_a_radius: true,
          radius: 10,
        },
        refresh: true,
      },
      { root: true },
    )

    // Filter excursions by geoloc
    // only for travellers
    if (state.appName === 'app') {
      dispatch(
        'filters/updateDefaultFilters',
        {
          type: 'excursions',
          values: {
            latitude: addressAttributes.latitude,
            longitude: addressAttributes.longitude,
            geoloc: getAttributeTranslation(pointOfInterest.attributes.title),
            limit_to_a_radius: false,
            radius: null,
          },
          refresh: true,
        },
        { root: true },
      )
    }
  }
}
